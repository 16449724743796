import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    showFormDiaryClass: false,
    cards: ['Today', 'Yesterday'],
      items: [
        { id: 1, form: 'showFormDiaryClass', icon: 'mdi-calendar-month-outline', title: 'Diário de Classe', subtitle: 'Cronograma de Aulas', color: 'orange lighten-2', show: false, text: "Cadastro de excessões"},
      ],
  }),
  computed: {
    ...mapState('auth', ['user'])
  },
  created() {
  },

  mounted() {

    
  },

  methods: {
    

    openForm(form){

      this[form] = true;

    }


  }
}